
import makeErrorMessage from './makeErrorMessage'

export default (promise, opts = {}) =>
  promise.catch(error => {
    console.log('handling error', error)
    const errorString = error.toString()
    error.toString = () => {
      try {
        return makeErrorMessage(error, opts)
      } catch (err) {
        console.error('ERROR managing error', err)
        return errorString
      }
    }
    throw error
  })
